import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Footer from '../components/Footer'
import Headline from '../components/Headline'
import IntroGradient from '../components/IntroGradient'
import LogoFixed from '../components/LogoFixed'
import Menu from '../components/Menu'
import ProjectTile from '../components/ProjectTile'
import Seo from '../components/Seo'

const Portfolio = () => {
  const { projects, count } = useStaticQuery(graphql`
    {
      projects: allContentfulProject(sort: { fields: date, order: ASC }) {
        nodes {
          slug
          title
          seo_title
          image {
            gatsbyImageData(
              quality: 100
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.33
            )
          }
          website
          logo {
            file {
              url
            }
          }
          desc
          desc2 {
            childMarkdownRemark {
              html
            }
          }
          role
        }
      }
      count: allContentfulProject {
        nodes {
          slug
        }
      }
    }
  `)

  return (
    <>
      <Seo 
        title='Portfolio Dyrektor Marketingu i Praktyk Biznesu'
        desc='Jestem praktykiem marketingu  i moim celem jest pomoc firmom w osiągnięciu sukcesu na rynku. Zapraszam do zapoznania się ze stroną i do kontaktu.' 
        url='/portfolio/' />
      <Menu />
      <LogoFixed />
      <IntroGradient />
      <Headline back='Portfolio'>
        <h1>
          Marki, w których odpowiadam za&nbsp;marketing
        </h1>
        <p>Witam na mojej stronie portfolio. Jestem praktykiem marketingu i&nbsp;moim celem jest pomoc firmom w osiągnięciu sukcesu na&nbsp;rynku. 
W&nbsp;tym&nbsp;portfolio prezentuję moje doświadczenie i&nbsp;umiejętności, a&nbsp;także pokazuję, jak&nbsp;kreatywnie i&nbsp;skutecznie potrafię prowadzić kampanie marketingowe. Zapraszam do&nbsp;zapoznania się ze&nbsp;stroną i&nbsp;do&nbsp;kontaktu, jeśli szukacie doświadczonego i&nbsp;profesjonalnego dyrektora marketingu w nowoczesnym modelu rozliczeniowym.</p>
      </Headline>
      {projects?.nodes?.map((project, id) => (
        <ProjectTile data={project} key={id} />
      ))}
      <Footer />
    </>
  )
}

export default Portfolio
